html{
 
    overflow-x: hidden !important;
    
    body{


            overflow-x: hidden !important;
            padding-right: unset !important;
            margin: 0px;

            .error{
                color: red;
                font-weight: bold;
            }


            .padding-outlet {
                 
                padding-top: 1%;
                padding-bottom: 10%;
                min-height:800px;

                .preloader-position{
                    position: relative;
                    left: 50%;
                    right: auto;
                    padding-top: 20%;
                    padding-bottom: 20%;
                    color: rgb(0, 93, 109) !important;
                }

            }


            .full-preloader-position{
                position: fixed;
                top: 50%;
                left: 50%;
                right: auto;
                width: 75px  !important;
                height: 75px !important;
                color: rgb(0, 93, 109) !important;
            }

            .space-around-tabs{ 
        
                .MuiTabs-scroller{
            
                    .MuiTabs-flexContainer{
                        justify-content:space-around
                    }
                }   
            }

            .color-navbar {
                color: #0db2ad;
            }
            
        
        
            .margin-auto{
                margin: auto;
            }
            
            .navbar-background{
            background:rgb(0 93 109);
         
             button{
                color: white;
             }
            }
            
            
            .notification-pos{
                position:relative;
                left:50%;
            }
        
        
            .search-input{
                background: #ffffff;
                color: #4f79a2;
                border-radius: 10px;
                font-weight: bold;
            }

            .bold {
                font-weight: bold;
            }
        
            .no-padding{
                padding-left:0;
                padding-right:0;
            }

            .box-shadow-unset{
                box-shadow: unset;
            }

            .hidden{
                display: none;
            }

            .css-aym9vq-MuiButtonBase-root-MuiTab-root {
                font-weight: 600 !important;
                font-size: 0.9rem !important;
             
            }

            .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
                color: #0db2ad;
            }
            

            .css-1aquho2-MuiTabs-indicator {
                position: absolute;
                height: 2px;
                bottom: 0;
                width: 100%;
                -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                background-color: #0db2ad;

               }

               .css-urznti-MuiContainer-root{
                
                * {
                    color: white;
                }
               }


               .css-1orzy9s {
                background-color: rgb(0 93 109);
            }

            .css-vih0ba-MuiGrid2-root{
                padding-top: 24px;
            }

            .padding-main-logo{
                padding-bottom: 0px;
            }

            .mobile-option{
                display: none;
            }

            .mobile-button{
                color: white;
            }

            .navbar-color{
                background-color: #005d6d;
                padding: 5%;
            }

            .notify-padding{
                padding-top: 3%;
                padding-bottom: 3%;
                font-weight: bold;
            }

            .notify{
                background-color: #0db2ad !important;
                color: white !important;
            }

            button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary.color-navbar.css-1tnnsql {
                font-weight: bold !important;
            }
            

  
     .footer{

        * {
          text-decoration: none;
          color: white;
          text-align: left;

          .about_desc{
            padding: 3%;
            max-width:85%;
          }
        }

        .social-media{
            margin-top: 2%;
            
            svg{
                border: solid;
                padding: 8px;
                border-radius: 25px;
                margin: 5px;
                background-color: #3f3f3f;
                border-color: #3f3f3f;

                &:hover{
                    background-color:#0db2ad;
                    border-color: #0db2ad;
                }
            }
        }

        .links{

            a{
                &:hover{
                    color:#0db2ad !important;
                }
            }
         
        }

        .last-section{
            background-color: white;
            padding: 10px 0px 10px 0px;
            position: relative;

            .MuiContainer-root{

                display: flex;

                .logo{
                    width: 60%;
                    height: auto;
                    img{
                        width: 134px;
                    }
                }
    
                .pos-copy-right{
                    width: 40%;
                    margin: auto;
                }
    
                .copy-right {

                    a{
                        text-align: right;
                        color: #0db2ad;
                        font-weight: 700;
                        font-size: 16px;
                    }
                }

            }
          
        }
      }

    }
    
}
