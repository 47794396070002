html{
    body{


        .chat-loading{
          position: absolute;
          top: 50%;
          right: 50%;
          left: auto;
          color:rgb(0 93 109) !important;
         }
    
          .back{
            width: 25% !important;
    
            &:hover{
              background: #0db2ad;
              color: white;
            }
          }
            .chat-img{
                width: 40px;
                height: 40px;
            }
    
            .chat-button {
    
                background: white;
                border-radius: 30px;
                padding: 30px;
    
                &:hover{
                    background: white;
                }
            }
    
            .chat-sidebar{
    
                .MuiDrawer-paperAnchorLeft,.MuiDrawer-paperAnchorRight{
                    width: 33.3%;
                }     
                       
    
            }
    
              .single-chat,.list-chat {
    
                text-align: center;
                margin-top: 8%;
    
    
                header {
                    background-image: linear-gradient(90deg, #005D6D, #005D6D, white);
                    height: 5vh;
                    min-height: 50px;
                    color: white;
                    position: fixed;
                    width: 33.3%;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    z-index: 99;
                    padding: 10px;
                    box-sizing: border-box;
                  }
    
                  section {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: 100vh;
                    background-color: white;
                  }
    
                  main {
                    padding: 10px;
                    height: 87vh;
                    overflow-y: scroll;
                    display: flex;
                    flex-direction: column;
                  }
                  
                
              
                  
                  form {
                    height: 5vh;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    display: flex;
                    font-size: 1.5rem;
    
                    button {
                        width: 3%;
                        background-color: rgb(0, 93, 109);
    
                        &:hover{
                            background-color: #0db2ad;
                          }
                      }
                  }
                  
                  
                  
                  input {
                    line-height: 1;
                    width: 28%;
                    font-size: 1.2rem;
                    background: rgb(228 228 228);
                    color: black;
                    outline: none;
                    border: none;
                    padding: 15px;
                  }
                  
                  button {
                    background-color: #ffffff;
                    border: none;
                    color: rgb(0, 93, 109);
                    padding-right: 15px;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 1.25rem;
                    border-radius: 5px;
                  }
    
    
                  .css-1d6wzja-MuiButton-startIcon {
                    display: inherit;
                    margin-right: 0px;
                    margin-left: 0px;
                }
                  
                  button:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                  }
                  
                  
                  .sign-in {
                    color: #282c34;
                    background: white;
                    max-width: 400px;
                    margin: 0 auto;
                  }
                  
                  
                  
                  
                  ul, li {
                    text-align: left;
                    list-style: none;
                  }
                  
                  p {
                    max-width: 500px;
                    margin-bottom: 5px;
                    line-height: 24px;
                    padding: 10px 20px;
                    border-radius: 25px;
                    position: relative;
                    color: white;
                    text-align: left;
                  }
                  
                  .message {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                  }
    
                  .img-chat{
                    margin-bottom: 20px;
                    margin-top: 0px;
                  }
                  
                  
                  .received {
                    flex-direction: row-reverse;
                  }
                  
                  .sent p {
                    color: white;
                    background: rgb(0, 93, 109);
                    align-self: flex-end;
                  }
                  .received p {
                    color: black;
                    background: #e5e5ea;
                  
                  }
                  
    
                  .css-168pop2-MuiList-root {
                    padding-top: 0px;
                    padding-bottom: 0px;
                }

                .MuiList-padding{
                  padding-top: 0px !important;
                  padding-bottom: 0px !important;
                }
              }
            
    
              .chat-sidebar , .chat-sidebar{
    
                .MuiDrawer-paperAnchorLeft,.MuiDrawer-paperAnchorRight{
                    overflow-y: hidden;
                }
    
              }
        
    
            .date{
                font-size: 14px;
                color: #838383;
            }
    
    
            .send-img-chat{
              max-width: 200px;
              height: auto;
            }
    
    
            .button-send-text {
              padding-top: 10px !important;
              padding-bottom: 5px !important;
              width: 3%;
            }
    
    
            .button-send-img {
             
              width: 3%;

              span {
                margin-right: 0px !important;
                margin-left: 0px !important;
              }
            }

    

    }

}

