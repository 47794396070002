html {

    body{


            .resend-button{
                background: #0db2ad;
    
                &:hover{
                    background-color: rgb(0, 93, 109);
                }
            }
    
            .login-form,.otp-form {
                margin-top: 8rem;
                display: 'flex';
                flex-direction: 'column';
                align-items: 'center';
    
                .margin-logo {
                    margin: 15%;
                }
            }
    
            .form-title{
                text-align: center;
                font-size:"30px";
            }
    
            .css-xsqe0f-MuiButtonBase-root-MuiButton-root {
                border: 1px solid rgb(13 178 173);
                color: #0db2ad;
            }
    
            .css-xsqe0f-MuiButtonBase-root-MuiButton-root:hover {
                border: 1px solid #0db2ad;
            }
    
            .terms-of-service{
                color: #005d6d;
                font-weight: bold;
                text-decoration: none;
            }

        
     
    }
}