html{
    body{
   
        .css-1hv8oq8-MuiStepLabel-label.Mui-active {
            font-weight: bold;
        }


        .package-size{
            margin-left: 30px;
            color: #6a7484;
            margin-bottom: 1rem;
        }

        .payment-description{
            padding-left: 5%;
            font-size: 16px;
            color: #514f4f;
        }


        .delivery-time{
            padding-left: 12%;
            font-size: 16px;
            color: #514f4f;
        }

        .box-request:hover{

            .delivery-time{
                color: white;
            }
        }

        .active {
          
            .delivery-time{
                color: white;
            }
        }


        .add-request{

            .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
                color: #005d6d;
            }
            .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
                color: #005d6d;
            }

            .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
                border: 1px solid rgb(0 93 109);
                color: #005d6d;
            }

        }

        .img-request{
            width: 50%;
            height: auto;
            margin-bottom: 5%;
            border-style: groove;
            border-color: #0db2ad;
            border-radius: 20px;
        }

        .map-info{
            display: inline;
            font-weight: bold;
        }

    }
}