html {

    body{

            .css-eqpfi5-MuiAccordionSummary-content {
                font-weight: bold;
            }
    
            #setting-heading{
                font-size: 1.3rem;
            }
    
            .social-media{
                overflow-x: auto;
            }

            #setting-heading{
                font-weight: bold;
            }

        }
    
}