:root {
    --swiper-theme-color: #005d6d;
}

#recaptcha-container{
    display: none;
}

html{
    body{

       /* class material ui */


        .MuiButton-contained{
        background-color: #0db2ad !important;
        color: white !important;
        }
        

        .Mui-disabled{
            background-color: rgba(0, 0, 0, 0.12) !important;
        }

        .MuiStep-horizontal{

            *{
                background-color: unset !important;
            }
           
        }

        .MuiBadge-badge{
            background-color: red !important;
        }

       
        .Mui-checked {
            color: #0db2ad !important;
        }

        .MuiButton-outlined:not(.accept,.reject,.delete-address)  {
            color: #0db2ad !important;
            border-color: #0db2ad !important;
        }

        .Mui-active {
            color: #0db2ad !important;
            font-weight: bold !important;
        }

        .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
            color: #0db2ad !important;
            border-color: #0db2ad !important;
        }
        

        .Mui-focused{

            color: #0db2ad !important;

            *{
                border-color: #0db2ad !important;
            }

            input{
                color: black !important;
            }
        }

        
        .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            color: #0db2ad !important;
            border-color: #0db2ad !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            color: #0db2ad !important;
            border-color: #0db2ad !important;
        }

        .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            color: #0db2ad !important;
            border-color: #0db2ad !important;
        }

        .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
            color: #0db2ad !important;
        }


        .css-18lrjg1-MuiCircularProgress-root{
             color: rgb(0, 93, 109) !important;
        }


        .Mui-selected {
            color: #0db2ad !important;
            font-weight: bold !important;
        }

        .css-ttwr4n{
            background-color: #0db2ad  !important;
        }

        .css-1p0wue5 {
            height: 46px !important;
        }

        .Mui-completed{
            color: rgb(0, 93, 109) !important;
        }

       /* */
   
      
       .font-bold{
        font-weight: bold;
       }

        .table-button{
            background-color: #0db2ad;

            &:hover {
                background-color: #0db2ad;
            }

        }

        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
            color: #0db2ad;
        }

        .font-weight-600{
            font-weight: 600;
        }

 
        .mt-2{
            margin-top: 2rem;
        }

        .mt-1{
            margin-top: 1rem;
        }

        .mb-1{
            margin-bottom: 1rem;
        }

        .mb-2{
            margin-bottom: 2rem;
        }

        .mb-0-5{
            margin-bottom: .5rem;
        }

        .ml-1{
            margin-left: 1rem;
        }

        .mr-0-5{
            margin-right: 0.5rem;
        }

        .mt-0-5{
            margin-top: .5rem;
        }
        
        .pt-2{
            padding-top: 2rem;
        }
        
        .p-1{
            padding: 1rem;
        }

        .p-0{
            padding:0rem
        }

        .text-undecoration{
            text-decoration: none;
        }

        .all-request,.all-address{

            .box-request{
                margin-top: 2%;
                margin-bottom: 2%;
                border-radius: 15px;
                color: black  ;
                background:rgba(0, 0, 0, 0.04);
                font-weight: bold;
                padding: 2.5%;
                font-size: 18px;
                border-color: rgba(0, 0, 0, 0.04);
         
                .dir-rtl{
                 direction: rtl;
                }
     
             }

             .box-request:hover{

                background-color: #0db2ad;
                color: white !important;
                
                .css-37jdci-MuiTypography-root{
                    color: white;
                }
                .css-i4bv87-MuiSvgIcon-root{
                    color: white;
                }
            }

            .active {
                background-color: #0db2ad;
                color: white;
            }

        }

        .select-address {

            display: flex;
            gap: 15px;

            .box-request{
                margin-top: 2%;
                margin-bottom: 2%;
                border-radius: 15px;
                color: black  ;
                background:rgba(0, 0, 0, 0.04);
                font-weight: bold;
                padding: 3%;
                font-size: 18px;
                border-color: rgba(0, 0, 0, 0.04);
                width: 100%;
             }

             .box-request:hover{

                background-color: #0db2ad;
                .css-37jdci-MuiTypography-root{
                    color: white;
                }
                .css-i4bv87-MuiSvgIcon-root{
                    color: white;
                }
            }

            .active {
                background-color: #0db2ad;
                color: white;
            }
        }


        .all-payment{

            .box-request{
                margin-top: 2%;
                margin-bottom: 2%;
                border-radius: 15px;
                color: black  ;
                background:rgba(0, 0, 0, 0.04);
                font-weight: bold;
                padding: 2.5%;
                font-size: 18px;
                border-color: rgba(0, 0, 0, 0.04);
         
                .dir-rtl{
                 direction: rtl;
                }
     
             }

             .box-request:hover{

                background-color: #0db2ad;
                color:  white;
                .css-37jdci-MuiTypography-root{
                    color: white;
                }
                .css-i4bv87-MuiSvgIcon-root{
                    color: white;
                }

                .payment-description{
                    color: white;
                }
            }

            .active {
                background-color: #0db2ad;
                color: white;

                .payment-description{
                    color: white;
                }
            }

        }

        .main-logo{
            width: 150px;
        }

        .auth-logo{
            width: 300px;
        }




        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            background-color: #0db2ad;
        }

        .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {

            background-color: rgb(0, 93, 109);
          
        }

        .css-1vhaqj4-MuiButtonBase-root-MuiButton-root{
            background-color: #0db2ad;
        }

        .css-1vhaqj4-MuiButtonBase-root-MuiButton-root:hover {

            background-color: rgb(0, 93, 109);
          
        }

        .css-1wgcmb5-MuiButtonBase-root-MuiButton-root {
            border: 1px solid #0db2ad;
            color:  #0db2ad;
        }

        .css-1wgcmb5-MuiButtonBase-root-MuiButton-root:hover {
            border: 1px solid rgb(0, 93, 109);
            color: rgb(0, 93, 109);
        }

        .css-18lrjg1-MuiCircularProgress-root {
            display: inline-block;
            color: #005d6d;
            -webkit-animation: animation-61bdi0 1.4s linear infinite;
            animation: animation-61bdi0 1.4s linear infinite;
        }
        
        
        .red{
            color: red;
        }

        .button-outlined{
          
            color: #0db2ad;
            border: 1px solid rgb(13 178 173);

            &:hover {
                border: 1px solid #0db2ad;
            }
                 
        }

        .button-verify{

            border: 1px solid #F44336;
            color: red;
            &:hover{
                border: 1px solid #F44336;
            }
          
        }

    
        .show-error{
            .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root{
                background-color: #F44336 !important;
                color: white !important;
                font-weight: bold;
            }

            .MuiSnackbarContent-root {
                background-color: #F44336 !important;
                color: white !important;
                font-weight: bold;
            }
        }
      
       
       
    }

    
    // update scroll style
    
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(0, 93, 109); 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #0db2ad; 
    }


}