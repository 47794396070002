html{
 
    body{
        
            .input-profile{

                .MuiFormControl-root{
                  margin-bottom: 15px;
                }
               
            }
    
            .profile-avatar{
                width: 100px;
                height: 100px;
            }
        }
      
}
  

